<template>
  <base-layout isNavDark>
    <template v-slot:content>
      <div class="wrapper wrapper-full-page">
        <div class="register-page">
          <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
          <div class="content">
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-md-offset-2">
                  <div class="header-text">
                    <h2 style="padding-top: 60px">Customer Risk Profile</h2>
                    <h4>
                      Better understanding among investors with regards to risk
                      tolerance
                    </h4>
                  </div>
                </div>

                <div class="col-md-10 col-md-offset-2">
                  <div class="col-lg-10 col-md-12">
                    <!-- <div class="card"> -->
                    <!-- <div class="card-header">
                        <h4 class="card-title">Tabs</h4>
                        </div> -->

                    <!-- @tab-click="handleClick" -->
                    <el-tabs
                      v-model="activeTab"
                      type="border-card"
                      class="card-content"
                    >
                      <el-tab-pane label="Result" name="result">
                        <div
                          id="containerIntro"
                          style="width:84%;margin-left:8%;margin-top:20px"
                        >
                          <h5><b>Acceptable risk levels : </b></h5>
                          Level {{ maxRiskAcceptanceLevel }}
                        </div>
                        <div
                          id="containerIntro"
                          style="width:84%;margin-left:8%"
                        >
                          <h5><b>Types of Investor : </b></h5>
                          {{ investorType }}
                        </div>
                        <br />
                        <div
                          id="containerIntro"
                          style="width:84%;margin-left:8%; margin-bottom:20px"
                        >
                          Moderate investors accept some risk to the principal
                          but adopt a balanced approach with intermediate-term
                          time horizons of five to 10 years. Combining
                          large-company mutual funds with less volatile bonds
                          and riskless securities, moderate investors often
                          pursue a 50/50 structure. A typical strategy might
                          involve investing half of the portfolio in a
                          dividend-paying, growth fund.
                        </div>
                        <br />
                      </el-tab-pane>
                      <el-tab-pane
                        label="Funds"
                        name="funds"
                        style="text-align: left;margin-left:30%"
                      >
                        <div
                          id="containerIntro"
                          style="margin-right:30%;margin-top:20px"
                        >
                          Risk levels in mutual fund from low complexity to high
                          complexity according to the result.
                        </div>
                        <br />
                        <div id="containerIntro">
                          <h5><b>Level 1 : </b></h5>
                          Domestic Money Market Fund
                        </div>
                        <div id="containerIntro">
                          <h5><b>Level 2 : </b></h5>
                          Money Market Fund with partial foreign investment
                        </div>
                        <div id="containerIntro">
                          <h5><b>Level 3 : </b></h5>
                          Government Bond Fund
                        </div>
                        <div id="containerIntro">
                          <h5><b>Level 4 : </b></h5>
                          Fixed Income Fund
                        </div>
                        <div id="containerIntro">
                          <h5><b>Level 5 : </b></h5>
                          Balanced Fund
                        </div>

                        <br />
                      </el-tab-pane>
                      <!-- <el-tab-pane
                          label="Recommendation"
                          name="recommend"
                          style="text-align: left;margin-left:30%;margin-top:20px"
                        >
                          <div id="containerIntro">
                            Basic Asset Allocation Portfolio Recommendation
                          </div>
                          <br />
                          <div id="containerIntro">
                            Deposit and Short Term Debt Instruments &#60; 10%
                          </div>
                          <div id="containerIntro">
                            Money Market Fund with partial foreign investment
                            &#60; 60%
                          </div>
                          <div id="containerIntro">
                            Equity &#60; 30%
                          </div>
                          <div id="containerIntro">
                            Alternative Instrument Investment &#60; 10%
                          </div>
                          <div id="chart">
                            <apexchart
                              type="donut"
                              :options="chartOptions"
                              :series="series"
                            ></apexchart>
                          </div>
                          <br />
                        </el-tab-pane> -->
                    </el-tabs>
                    <!-- </div> -->
                  </div>
                </div>
                <div class="col-md-6 col-md-offset-4">
                  <div id="chart">
                    <apexchart
                      type="donut"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                    <button type="button" class="btn btn-fill btn-wd">
                      <router-link :to="{ name: 'Home' }"
                        ><a style="color:white">Back to home</a></router-link
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/layouts/BaseLayout";
import useUserData from "@/composables/userData";
export default {
  components: {
    BaseLayout,
  },
  setup() {
    const { maxRiskAcceptanceLevel, investorType } = useUserData();

    return {
      maxRiskAcceptanceLevel,
      investorType,
    };
  },
  data() {
    return {
      activeTab: "result",
      series: [10, 25, 35, 20, 10],
      chartOptions: {
        chart: {
          type: "pie",
        },
        labels: [
          "Deposit and Short Term Debt",
          "Government Bonds",
          "Private Debt Instruments",
          "Equity",
          "Alternative Investment",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
};
</script>
<style>
#containerIntro h5,
#containerIntro p {
  display: inline;
}
</style>
